.login-form {
	margin-left: 20%;
	margin-right: 20%;
	margin-top: 10%;
	margin-bottom: 10%;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 80px;
	border-radius: 30px;
}
.input {
	border: 0 !important;
	height: 50px;
	width: 100%;
	font-family: 'Arial';
	padding-left: 10px;
	box-shadow: 10px 10px 20px 1px rgba(68, 68, 68, 0.4);
}
.input:focus {
	outline-color: #3d66d3;
}
.input-wrapper {
	box-shadow: -5px 0px #3d66d3;
	height: 50px;
}
.button {
	margin-top: 5%;
	background: #3d66d3;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;
	box-shadow: 5px 5px 20px rgba(68, 68, 68, 0.6);
	border: none;
	border-radius: 25px;
	color: white;
	font-weight: bold;
	display: inline-block;
	line-height: 1;
	font-weight: 300;
	text-decoration: none;
}
.sidebar-login {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	min-height: 100vh !important;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper-login{
	min-height: 100vh !important;
	width: 50vw;
	background-color: black;
	text-align: center;
	padding-top: 15%;
}